.btn {
    @include flex(center, space-between);
    column-gap: 5px;
    font-size: 16px;
    line-height: 100%;
}

.edit__btn {
}

.delete__btn {
}

.edit__btn,
.delete__btn {
    font-weight: 500;
    padding-inline: 15px;
}
