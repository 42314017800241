.menu__icon {
    font-size: 20px;
    font-weight: bold !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
        sans-serif;
    display: flex;
}
.sidebar__menu {
    width: 100%;
    user-select: none;
    border-inline-end: none !important;
    display: grid;
    row-gap: 5px;

    .ant-menu-item {
        padding-block: 22px;
    }
}

.collapse {
    @include flex();
    padding-block: 20px;

    &__content {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        column-gap: 7px;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
    }
}
