*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #0c79ad;
    box-shadow: inset 2px 2px 5px 0 hsl(0deg 0% 100% / 50%);
}

a {
    text-decoration: none;
}

ul,
li,
ol {
    list-style: none;
}

input,
button,
textarea {
    outline: none;
}

html {
    scroll-behavior: smooth;
}
