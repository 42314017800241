.loading-spin {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex();
    background: rgba(0, 0, 0, 0.08);
    z-index: 8;
}
