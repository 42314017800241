$border-radius: 3px;

.menu-dnd {
    z-index: 9;

    &__context {
        display: grid;
        row-gap: 5px;
        overflow: hidden;
    }

    &__text {
        font-size: 16px;
    }

    &__item {
        @include flex(center, flex-start, column);
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: $border-radius;
        z-index: 1;

        &-header {
            width: 100%;
            background: #ebebeb;
            padding: 20px 30px;
            @include flex(center, space-between);
        }
    }

    &__subtable {
        width: 100%;
        max-height: 0;
        height: 100%;
        opacity: 0;
        transition: opacity 0.2s ease;
        padding-inline: 15px;

        &.active {
            padding-block: 10px;
            max-height: 9999px;
            opacity: 1;
        }
    }

    &__loading {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00000005;
        border-radius: $border-radius;
        z-index: 2;
    }

    &__holder {
        font-size: 22px;
        cursor: grab;
    }
}
