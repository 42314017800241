body {
    font-size: 16px;
    font-weight: normal;
    line-height: 100%;
}

.wrapper {
    max-width: 100vw;
    min-height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: $menu-size 1fr;
    grid-template-rows: $header-size 1fr;
    grid-template-areas: 'header header' 'aside main';
    transition: all 0.35s ease;

    &.active {
        grid-template-columns: $collapsed-menu 1fr;
    }
}

.container {
    width: 100%;
    padding: 0 20px;
}

.layout {
    background: #fff;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 5px 7px -5px rgba(34, 60, 80, 0.6);
    @include flex(center, space-between);
    grid-area: header;
    z-index: 10;

    &__items {
        @include flex(center, space-between);
    }

    &__link {
        @include flex(center, center);
        column-gap: 7px;
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $menu-size;
    grid-area: aside;
    margin-top: 60px;
    padding: 15px 5px;
    @include flex(center, space-between, column);
    transition: width 0.33s ease;

    &.active {
        width: $collapsed-menu;
    }
}

.main {
    width: 100%;
    grid-area: main;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;

    &__container {
        background: #fff;
        width: 100%;
        height: 100%;
        padding: 20px 40px;
    }
}

.icon {
    font-size: 22px !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.active {
        transform: rotate(-180deg);
    }
}

.crud__actions {
    @include flex(center, flex-end);
    width: 100%;
}
