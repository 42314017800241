.page {
    display: grid;
    row-gap: 1.5rem;
    z-index: 9;

    &__header {
        @include flex(center, space-between);
    }

    &__title {
        font-size: $font-size-title;
        font-weight: normal;
        font-family: $font-opensans;
    }

    &__action {
        @include flex(center, space-between);
        column-gap: 10px;
    }

    &__content {
        position: relative;
    }

    &-fullform {
        min-height: 100vh;
        height: 100%;
        @include flex(flex-start, space-between);
        column-gap: 24px;

        @media screen and (max-width: 900px) {
            @include flex(flex-start, center, column);
            row-gap: 24px;
        }

        &__aside {
            flex: 0 1 25%;
            min-width: 300px;
            border: 1px solid #eee;
            border-radius: 10px;
            padding: 5px 15px 10px 15px;

            @media screen and (max-width: 900px) {
                width: 100%;
            }
        }

        .ant-form-item {
            margin-bottom: 10px;

            &-row {
                display: block;
            }
        }
    }
}

.tabs {
    flex: 1 1 auto;
    padding: 10px 15px;
    border-radius: 10px;
    border: 1px solid #eee;

    @media screen and (max-width: 900px) {
        margin-top: 5rem;
    }
}

.ql-editor {
    min-height: 290px;
}
