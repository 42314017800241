$font-inter: 'Inter', sans-serif;
$font-opensans: 'Open Sans', sans-serif;

$font-size-title: 32px;
$header-size: 60px;
$menu-size: 280px;
$collapsed-menu: 88px;

%transition {
    transition: all 0.4s ease;
}
