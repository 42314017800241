$border: 1px solid #33333388;
$border-radius: 4px;

.pages-wrap {
    &__items {
        display: grid;
        row-gap: 5px;
    }

    &__item {
        @include flex(center, space-between, column);
        background: #eee;
        border: $border;
        border-radius: $border-radius;
        overflow: hidden;

        &-header {
            width: 100%;
            @include flex(center, space-between);
            padding: 20px 40px;
            border: none;
            border-radius: $border-radius;

            &.active {
                border-bottom: $border;
            }
        }

        &-bottom {
            width: 100%;
            max-height: 0;
            height: 100%;
            padding-inline: 15px;
            display: none;
            background: #fff;

            &.active {
                padding-block: 10px;
                max-height: 9999px;
                display: block;
            }
        }
    }

    &__title {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
    }
}

.pages-bottom {
    padding: 20px 40px;

    &__header {
        margin-bottom: 10px;
    }

    &__title {
        flex: 0 1 auto;
        font-size: 20px;
    }

    &__items {
        display: grid;
        row-gap: 5px;
    }

    &__item {
        border: $border;
        border-radius: $border-radius;
        overflow: hidden;
    }

    &__subheader {
        @include flex(center, space-between);
        border: none;
        border-radius: $border-radius;
        padding: 10px 20px;
        background: #f1f1f1;

        &.active {
            border-bottom: $border;
        }
    }

    &__subtitle {
        @include flex(flex-start, space-between, column);
        row-gap: 7px;
    }

    &__badge {
        border-radius: 10px;
        line-height: 120%;
        padding: 3px 10px;
        color: #fff;
        font-weight: 600;

        &.blue {
            background: #0083b0;
        }
        &.red {
            background: #dc281e;
        }
        &.green {
            background: #56ab2f;
        }
    }

    &__text {
        font-size: 16px;
    }

    &__subbottom {
        width: 100%;
        max-height: 0;
        height: 100%;
        padding-inline: 15px;
        display: none;
        background: #fff;

        &.active {
            padding-block: 10px;
            max-height: 9999px;
            display: block;
        }
    }

    .fixed-crud__actions {
        width: 50%;
    }
}
